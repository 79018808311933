/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {useStaticQuery, graphql, PageProps} from "gatsby"
import HTMLReactParser from "html-react-parser"

import {macroReplacer} from "../transformers"

import {Page} from "../../types"

import Layout from "../components/layout"
import Sidebar from "../components/sidebar"
import SingleBanner from "../components/single-banner"

const ThemePageTemplate = (page: PageProps): JSX.Element => {
  const data = page.pageContext as Page
  const landingPages = useStaticQuery(graphql`
    query themesLandingPageQuery {
      pages: allDatoCmsPage(
        filter: {pageType: {key: {eq: "themes-landing-page"}}}
      ) {
        edges {
          node {
            header {
              bannerTitle
              bannerText
              bannerImage {
                url
              }
              bannerCta {
                slug
              }
              bannerCtaText
            }
          }
        }
      }
    }
  `).pages.edges

  const headerContent = {
    bannerTitle: landingPages[0].node.header[0].bannerTitle,
    bannerImage: {
      url: data.visual?.url || landingPages[0].node.header[0].bannerImage.url,
    },
  }

  return (
    <Layout title={data.title} currentPage={data}>
      <SingleBanner {...headerContent} size="small" />
      <Sidebar
        currentPage={data}
        landingPageType="themes-landing-page"
        alphabetised
      >
        <h3>{data.title}</h3>
        <div css={ThemePageStyles}>
          {HTMLReactParser(data.content, {
            replace: object => macroReplacer(data, object),
          })}
        </div>
      </Sidebar>
    </Layout>
  )
}

export default ThemePageTemplate

const ThemePageStyles = css`
  max-width: 80rem;

  h1,
  h2,
  h3,
  h4 {
    margin: 4rem 0 1rem;
    font-size: 2.5rem;
    line-height: 3rem;
  }
`
